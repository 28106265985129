import React from "react"
import Content from "./content"
import { OutboundLink } from "gatsby-plugin-gtag"

export const sections = [
  {
    heading: "What is the difference between different types of gloves?",
    body: (
      <>
        <ol>
          <li>
            <strong>Latex gloves</strong> are made of natural rubber{" "}
            <strong>latex</strong>, and are are very flexible and stretchy.{" "}
          </li>
          <li>
            <strong>Nitrile gloves</strong> are made of petroleum based
            synthetic material, and are better at resisting tears and punctures,
            and protect against hazardous chemicals.{" "}
          </li>
          <li>
            <strong>Polychloroprene gloves</strong> are latex-free, and provide
            the comfort and feel of Latex and the barrier and strength of
            Nitrile. They offer offers moderate resistance to oils and petroleum
            products, but provides significantly greater resistance to sunlight,
            ozone, and weather.
          </li>
          <li>
            <strong>Rubber gloves</strong> can be unsupported (
            <strong>rubber</strong> only) or supported (<strong>rubber</strong>{" "}
            coating of textile glove). Its primary purpose is protection of the
            hands while performing tasks involving chemicals.{" "}
          </li>
        </ol>
      </>
    ),
  },
  {
    heading: "How should disposable gloves be used?",
    body: (
      <>
        <p>
          The CDC recommends that workers wear disposable gloves during the
          following activities:
        </p>
        <ul>
          <li>
            <OutboundLink href="https://www.osha.gov/SLTC/covid-19/environmental-services.html">
              Cleaning and disinfecting surfaces
            </OutboundLink>
          </li>
          <li>
            <OutboundLink href="https://www.osha.gov/SLTC/covid-19/in-home-repair.html">
              Touching items also touched by customers or other employees, such
              as pens or merchandise
            </OutboundLink>
          </li>
          <li>
            <OutboundLink href="https://www.osha.gov/Publications/OSHA3990.pdf">
              Any physical human contact, such as at a nail salon
            </OutboundLink>
          </li>
        </ul>
        <p>
          The{" "}
          <OutboundLink href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/gloves.html">
            CDC instructs
          </OutboundLink>{" "}
          that disposable gloves should be disposed after each use and should
          never be washed or disinfected. Gloves should always be changed if
          they are ripped or torn.
        </p>
      </>
    ),
  },
  {
    heading: "What should you look for when shopping for disposable gloves?",
    body: (
      <>
        <p>
          The{" "}
          <OutboundLink href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/respirator-use-faq.html">
            CDC
          </OutboundLink>{" "}
          only comments on glove materials in medical settings, but notes many
          different materials, including nitrile, natural rubber,
          polychloroprene, and vinyl, can be used.
        </p>
        <p>
          If gloves are used for cleaning purposes, the cleaning agent’s{" "}
          <OutboundLink href="https://choosehandsafety.org/choosing-gloves/checking-labels">
            Safety Data Sheet
          </OutboundLink>{" "}
          should be consulted to ensure that it can safely be used with the
          glove material.
        </p>
      </>
    ),
  },
  {
    heading: "What are standards for gloves?",
    body: (
      <>
        <p>
          Gloves should be large enough to fit securely on the hands without
          wrinkling or tearing. As per the{" "}
          <OutboundLink href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/respirator-use-faq.html">
            CDC
          </OutboundLink>
          , double gloving and extended length gloves are not necessary, even
          when providing care to COVID-19 patients.
        </p>
      </>
    ),
  },
]

const DisposableGlovesContent = () => <Content sections={sections} />

export default DisposableGlovesContent
