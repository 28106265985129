import React, { useContext } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import locale from "../../locale/en"
import {
  CalculatorLayout,
  CalculatorInput,
  CalculatorTotal,
  CalculatorTimePeriodSelect,
  CalculatorButtons,
  CalculatorResultsBox,
  CalculatorGuidelines,
} from "../../components/calculator"
import { sections as faqSections } from "../../components/faq-content/disposable-gloves"
import Fieldset from "../../components/fieldset"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import logger from "../../lib/logger"
import { generateOnSubmit } from "../../lib/calculator-sections"
import { CalculatorContext } from "../../providers/calculator-provider"
const log = logger.extend("calculator:disposable-gloves")

const { calculator, faq } = locale

const SECTION_KEY = "disposable_gloves"

const RESET_KEYS = [
  "pairs_of_nitrile_gloves_per_employee",
  "pairs_of_rubber_gloves_per_employee",
  "pairs_of_vinyl_gloves_per_employee",
  "pairs_of_polychloroprene_gloves_per_employee",
  "pairs_of_nitrile_gloves_per_customer",
  "pairs_of_rubber_gloves_per_customer",
  "pairs_of_vinyl_gloves_per_customer",
  "pairs_of_polychloroprene_gloves_per_customer",
]

const CalculatorDisposableGloves = () => {
  const { register, errors, handleSubmit } = useForm()
  const { calculatorData } = useContext(CalculatorContext)

  const onSubmit = generateOnSubmit(SECTION_KEY)
  const hasErrors = !!Object.keys(errors).length
  const { number_of_customers_per_week } = calculatorData

  if (hasErrors) {
    log("Form errors:", errors)
  }

  return (
    <Layout>
      <SEO title={`${calculator.title} - ${faq.disposable_gloves}`} />
      <CalculatorLayout sectionKey={SECTION_KEY}>
        <h2 className="text-black mb-5">{faq.disposable_gloves}</h2>
        <CalculatorResultsBox>
          <div>
            <CalculatorTotal totalKey="nitrile_gloves_total" />
            <div className="text-sm">Nitrile gloves</div>
          </div>
          <div>
            <CalculatorTotal totalKey="rubber_gloves_total" />
            <div className="text-sm">Rubber gloves</div>
          </div>
          <div>
            <CalculatorTotal totalKey="polychloroprene_gloves_total" />
            <div className="text-sm">Polychloroprene gloves</div>
          </div>
          <div>
            <CalculatorTotal totalKey="vinyl_gloves_total" />
            <div className="text-sm">Vinyl gloves</div>
          </div>
        </CalculatorResultsBox>

        <form className="container mt-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-2 mb-5 justify-between">
            <div className="w-1/2">
              <h3 className="text-black mb-4">Inputs</h3>
              <fieldset className="mb-5">
                On a{" "}
                <CalculatorTimePeriodSelect
                  className="inline-block"
                  name="disposable_gloves_time_period"
                  register={register}
                />{" "}
                basis, my business needs, per employee:
              </fieldset>
              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="pairs_of_nitrile_gloves_per_employee"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="pairs_of_nitrile_gloves_per_employee"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="pairs_of_nitrile_gloves_per_employee">
                  pairs of nitrile gloves
                </label>
              </Fieldset>
              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="pairs_of_rubber_gloves_per_employee"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="pairs_of_rubber_gloves_per_employee"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label
                  htmlFor="pairs_of_rubber_gloves_per_employee"
                  className="flex-grow"
                >
                  pairs of rubber gloves
                </label>
              </Fieldset>
              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="pairs_of_polychloroprene_gloves_per_employee"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="pairs_of_polychloroprene_gloves_per_employee"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="pairs_of_polychloroprene_gloves_per_employee">
                  pairs of polychloroprene gloves
                </label>
              </Fieldset>
              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="pairs_of_vinyl_gloves_per_employee"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="pairs_of_vinyl_gloves_per_employee"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="pairs_of_vinyl_gloves_per_employee">
                  pairs of vinyl gloves
                </label>
              </Fieldset>

              {number_of_customers_per_week > 0 ? (
                <>
                  <p className="mb-5">per customer:</p>

                  <Fieldset
                    errorMessage={
                      <ErrorMessage
                        errors={errors}
                        name="pairs_of_nitrile_gloves_per_customer"
                      />
                    }
                  >
                    <CalculatorInput
                      type="number"
                      name="pairs_of_nitrile_gloves_per_customer"
                      className="w-16 mr-5"
                      register={register({
                        min: {
                          value: 0,
                          message: "Must be 0 or greater",
                        },
                      })}
                    />
                    <label htmlFor="pairs_of_nitrile_gloves_per_customer">
                      pairs of nitrile gloves
                    </label>
                  </Fieldset>
                  <Fieldset
                    errorMessage={
                      <ErrorMessage
                        errors={errors}
                        name="pairs_of_rubber_gloves_per_customer"
                      />
                    }
                  >
                    <CalculatorInput
                      type="number"
                      name="pairs_of_rubber_gloves_per_customer"
                      className="w-16 mr-5"
                      register={register({
                        min: {
                          value: 0,
                          message: "Must be 0 or greater",
                        },
                      })}
                    />
                    <label
                      htmlFor="pairs_of_rubber_gloves_per_customer"
                      className="flex-grow"
                    >
                      pairs of rubber gloves
                    </label>
                  </Fieldset>
                  <Fieldset
                    errorMessage={
                      <ErrorMessage
                        errors={errors}
                        name="pairs_of_polychloroprene_gloves_per_customer"
                      />
                    }
                  >
                    <CalculatorInput
                      type="number"
                      name="pairs_of_polychloroprene_gloves_per_customer"
                      className="w-16 mr-5"
                      register={register({
                        min: {
                          value: 0,
                          message: "Must be 0 or greater",
                        },
                      })}
                    />
                    <label htmlFor="pairs_of_polychloroprene_gloves_per_customer">
                      pairs of polychloroprene gloves
                    </label>
                  </Fieldset>
                  <Fieldset
                    errorMessage={
                      <ErrorMessage
                        errors={errors}
                        name="pairs_of_vinyl_gloves_per_customer"
                      />
                    }
                  >
                    <CalculatorInput
                      type="number"
                      name="pairs_of_vinyl_gloves_per_customer"
                      className="w-16 mr-5"
                      register={register({
                        min: {
                          value: 0,
                          message: "Must be 0 or greater",
                        },
                      })}
                    />
                    <label htmlFor="pairs_of_vinyl_gloves_per_customer">
                      pairs of vinyl gloves
                    </label>
                  </Fieldset>
                </>
              ) : null}

              <p className="text-gray-400 text-sm">
                Gloves may be sold varying counts, so check to determine your
                appropriate packaging size
              </p>

              <CalculatorButtons
                register={register}
                sectionKey={SECTION_KEY}
                resetKeys={RESET_KEYS}
              />
            </div>
            <CalculatorGuidelines sections={faqSections} />
          </div>
        </form>
      </CalculatorLayout>
    </Layout>
  )
}

export default CalculatorDisposableGloves
